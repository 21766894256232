export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  UseCustomizableCounterInCart: 'specs.stores.UseCustomizableCounterInCart',
  UseGlobalCssForOneColumnCart: 'specs.stores.UseGlobalCssForOneColumnCart',
  HideCountryPickerInCart: 'specs.stores.HideCountryPickerInCart',
  ShouldPreloadPaymentMethods: 'specs.stores.ShouldPreloadPaymentMethods',
  GetCartWithConsentRequiredPaymentPolicyOnLineItem: 'specs.stores.GetCartWithConsentRequiredPaymentPolicyOnLineItem',
  SupportCardTokenizationOnCartAndCheckout: 'specs.stores.SupportCardTokenizationOnCartAndCheckout',
  NewTagsForCart: 'specs.stores.NewTagsForCart',
  UseDeleteIconOnLineItems: 'specs.stores.UseDeleteIconOnLineItems',
  UseThreeDotsLoaderOnCheckoutButton: 'specs.stores.UseThreeDotsLoaderOnCheckoutButton',
  WaitForCashierExpressStoreInit: 'specs.stores.WaitForCashierExpressStoreInit',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldPreloadCurrentCart: 'specs.stores.ShouldPreloadCurrentCart',
  ShouldUseNewCartQueryAndMutations: 'specs.stores.ShouldUseNewCartQueryAndMutations',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  Use20PaddingForCart: 'specs.stores.Use20PaddingForCart',
} as const;
